import React, { useEffect, useState, useRef } from "react"
import { toCamelCase } from "../../utils/utils"
import formsData from "../../_data/seamless-forms.json"
import Seamless from "../../components/SeamlessEmbed"

const LocationForms = ({ activeForm, pageUpdated }) => {
  if (!activeForm.id) return ""

  const [filename, setFilename] = useState("")

  const formContainer = useRef()

  const makeFilename = name => `/${toCamelCase(name)}Form.js`

  useEffect(() => {
    const findForm = formsData.allSeamlessForms.find(
      form => form.seamlessShortcode === activeForm.id
    )

    if (activeForm.id && findForm) {
      setFilename(makeFilename(findForm.title))

      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({
          top: form.offsetTop - 100,
          left: 0,
          behavior: "smooth"
        })
      }, 600)
    }

    return () => {
      setFilename("")
    }
  }, [activeForm.id, pageUpdated])

  return (
    <div className="columns contact-bottom location-contact-form">
      <div className="column is-4"></div>
      <div className="column">
        <center>
          <div style={{ maxWidth: "1000px" }}>
            <div id="contact-page-form">
              {activeForm.name && <h3>{`Message Our Office`}</h3>}
              {/* {activeForm.name && <h3>{`Message Our ${activeForm.name} Office`}</h3>} */}
              <p className="required-fields">
                <span className="required-asterisk">*</span>
                Required Fields
              </p>
            </div>
            <div
              style={{
                minHeight: activeForm.name ? "600px" : 0
              }}
              ref={formContainer}
              className="seamless-form-wrapper">
              <Seamless id={activeForm.id} />
            </div>
          </div>
        </center>
      </div>

      <div className="column is-4"></div>
    </div>
  )
}

export default LocationForms
